import React, { FC, useEffect, useState } from 'react'
import axiosRetry from 'axios-retry'
import { connect } from 'react-redux'
import { parseCookies } from 'nookies'
import { useRouter } from 'next/router'
import { client } from 'utils/axios'
import Job from 'components/job/Job'
// import JobAd from 'components/paid/JobAdIsadore'
import FreelancerHomepage from 'components/freelancers/FreelancerHomepage'
import Filter from 'components/filter/Filter'
import HomepageMeta from 'components/HomepageMeta'
import Newsletter from 'components/newsletter/Newsletter'
import MagazineLatestPosts from 'components/magazine/LatestPosts'
import FilterUtils from 'utils/filter'
import TrackingUtils from 'utils/tracking'
import LazyLoadButton from 'components/LazyLoadButton'
import FilterLocalStorage from 'components/filter/FilterLocalStorage'
import ContactButton from 'components/ContactButton'
import { getFromLocalStorage } from 'utils/local-storage'
import EmptyState from 'components/EmptyState'
import Search from 'components/search'
import { Locale } from 'translations/types'
import { PaymentType } from 'types/filter'
import useTranslation from 'translations/hooks/useTranslation'
import NewsletterModalCta from 'components/newsletter/NewsletterModalCta'
import Constants from 'server/constants'
import { useFilter } from 'hooks/useFilter'
import HomePageHotCompanies from 'components/companies/HomePageHotCompanies'
import ProFreelancersHomepageBanner from 'components/freelancers/ProFreelancersHomepageBanner'
import type {
  City,
  Country,
  FilterQuery,
  JobCategory,
  JobType,
  SkillLevel,
  Tag,
} from 'types/filter'
import type { ICompany } from 'redux/interfaces/company'

axiosRetry(client, { retries: 3 })

const NL_JOBS_COUNT = 15
const FILTER_STORAGE_KEY = 'company-jobs-filter'

interface IFilterDataResponse {
  jobTypes: JobType[]
  cities: City[]
  countries: Country[]
  jobCategories: JobCategory[]
  skillLevels: SkillLevel[]
  tags: Tag[]
}

interface IProps {
  jobs?: any
  total?: number
  newTotal?: number
  query?: any
  company?: ICompany
  filterData?: IFilterDataResponse
  page: number
}

interface IState {
  filterVisible: boolean
  loading: boolean
  jobs: any
  jobsLoaded: number
  total: number
  newTotal: number
  isCompany: boolean
  lastFilterSettings: FilterQuery | null
  page: number
}

const Index: FC<IProps> = (props) => {
  const router = useRouter()
  const [state, setState] = useState<IState>({
    filterVisible: false,
    loading: false,
    jobs: props.jobs,
    jobsLoaded: props.jobs?.length || 0,
    total: props.total,
    newTotal: props.newTotal,
    isCompany: false,
    lastFilterSettings: null,
    page: props.page,
  })
  const { t, locale } = useTranslation()
  const pageFilter = useFilter(
    router.query,
    [
      ...Object.keys(props.filterData).map((key) => key),
      'salary',
      'new',
      'searchTerm',
      'paymentTypes',
      'maxAge',
    ],
    {
      onUpdate: !!router.query.page ? undefined : updateJobs,
    }
  )

  const updateState = (newValue: Partial<IState>) => {
    setState((prev) => ({
      ...prev,
      ...newValue,
    }))
  }

  useEffect(() => {
    updateState({ isCompany: parseCookies()['userSegment'] === 'audience-company' })

    if (props.query.jobCategories) {
      const categories = FilterUtils.splitQueryString(props.query.jobCategories) || []

      if (categories[0]) {
        TrackingUtils.event('category', { event_action: categories[0] })
      }
    }

    const lastFilterSettings = getFromLocalStorage<FilterQuery>(FILTER_STORAGE_KEY)

    updateState({
      isCompany: parseCookies()['userSegment'] === 'audience-company',
      lastFilterSettings,
    })
  }, [])

  const getJobs = async (nextQuery: FilterQuery, skip?: number) => {
    const query = FilterUtils.buildJobsQuery({
      ...nextQuery,
      skip: skip || 0,
    })

    const { data } = await client.get(`api/job/list/${query}`)

    if (data.status === 'ok') {
      return {
        jobs: data.jobs,
        total: data.total,
        newTotal: data.newTotal,
      }
    }

    return {
      jobs: state.jobs,
      total: state.total,
      newTotal: state.newTotal,
    }
  }

  const getMoreJobs = async () => {
    const nextPage = state.page + 1
    const { jobs: nextJobs } = await getJobs(pageFilter.query, state.jobsLoaded)
    const jobs = state.jobs.concat(nextJobs)

    const nextQuery = FilterUtils.buildJobsQuery({ ...pageFilter.query, page: nextPage })
    router.push(nextQuery, nextQuery, { shallow: true })

    updateState({
      jobs,
      jobsLoaded: jobs.length,
      page: nextPage,
    })
  }

  async function updateJobs(nextQuery) {
    const { jobs, total, newTotal } = await getJobs(nextQuery)

    updateState({
      jobs,
      total,
      newTotal,
      loading: false,
      page: 1,
      jobsLoaded: jobs.length,
    })
  }

  const addTagToQuery = (value: string) => {
    pageFilter.addToQuery('tags', value)
  }

  const addSkillLevelToQuery = (value: string) => {
    pageFilter.addToQuery('skillLevels', value)
  }

  const { cities, countries, jobTypes, jobCategories, skillLevels, tags } =
    props.filterData

  const { jobs, total, lastFilterSettings } = state

  const canFetchMore = jobs.length < total

  return (
    <>
      <HomepageMeta
        jobCategories={jobCategories}
        jobTypes={jobTypes}
        skillLevels={skillLevels}
        cities={cities}
        countries={countries}
        tags={tags}
        query={pageFilter.query}
        total={total || jobs.length}
      />
      <ContactButton context="index" />
      <div className="container hot-companies-container">
        <HomePageHotCompanies />
      </div>
      <div className="search container no-padding-top">
        <div className="search__container">
          <Search
            context="jobs"
            filterData={props.filterData}
            addToQuery={(key, value) => pageFilter.replaceQuery(key, [value])}
            placeholder={t('common.search.placeholderJobs')}
            onResetFilterClick={pageFilter.isActive ? pageFilter.resetFilter : undefined}
          />
        </div>
      </div>
      <Filter
        context="jobs"
        onResetSummary={pageFilter.resetFilter}
        filterData={{
          ...props.filterData,
          paymentTypes: [
            {
              _id: PaymentType.Tpp,
              slug: PaymentType.Tpp,
              name: t(`filter.paymentType.type[${PaymentType.Tpp}]`),
            },
            {
              _id: PaymentType.Invoice,
              slug: PaymentType.Invoice,
              name: t(`filter.paymentType.type[${PaymentType.Invoice}]`),
            },
          ],
        }}
        additionalFilter={{
          salary: {
            query: {
              key: 'salary',
            },
          },
          new: {
            count: props.newTotal,
            query: {
              key: 'maxAge',
              value: String(Constants.JOB_NEW_MAX_AGE),
            },
          },
        }}
        activeQueries={pageFilter.activeQueries}
        isActive={pageFilter.isActive}
        query={pageFilter.query}
        onSelectOption={pageFilter.addToQuery}
        onUnselectOption={pageFilter.removeFromQuery}
        onReplaceOption={pageFilter.replaceQuery}
        onLastFilterClick={() => pageFilter.forceQuery(lastFilterSettings)}
        lastFilterSettings={lastFilterSettings}
        extraElement={
          <NewsletterModalCta activeCategories={pageFilter.query.jobCategories} />
        }
        resultsCount={state.total}
        onActiveFilterPillClick={(key, value) => {
          if (key === 'salary') {
            pageFilter.replaceQuery('salary', '')
          } else {
            pageFilter.removeFromQuery(key, value)
          }
        }}
      />

      <FilterLocalStorage storageKey={FILTER_STORAGE_KEY} query={pageFilter.query} />

      <>
        {props.company && (
          <ProFreelancersHomepageBanner
            topPosition
            renderWithoutFold={jobs.length === 0}
          />
        )}
        {/*{jobs.length != 0 && <JobAd />}*/}
        {jobs.map((job, index) => {
          if (jobs.length >= NL_JOBS_COUNT && index === NL_JOBS_COUNT - 1) {
            return (
              <React.Fragment key={'fragment-' + job._id}>
                <Job
                  key={job._id}
                  job={job}
                  onTag={addTagToQuery}
                  onSkillLevel={addSkillLevelToQuery}
                />
                {locale !== Locale.En && !props.company && (
                  <FreelancerHomepage key={'nl-' + index} />
                )}
              </React.Fragment>
            )
          }
          return (
            <Job
              key={job._id}
              job={job}
              onTag={addTagToQuery}
              onSkillLevel={addSkillLevelToQuery}
            />
          )
        })}

        {jobs.length === 0 && <EmptyState translationKey="common.noOfferFound" />}
      </>
      <div className="container center margin-top margin-bottom">
        {canFetchMore && <LazyLoadButton fetchPosts={getMoreJobs} />}
      </div>

      <Newsletter />
      {locale !== Locale.En && <MagazineLatestPosts />}
    </>
  )
}

const mapStateToProps = ({ company }) => ({ company })

export default connect(mapStateToProps)(Index)
